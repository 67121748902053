<template>
  <div>
    <!-- Toolbar -->
    <div
      style="width: 86%;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 30px;
    "
    >
      <v-toolbar dense :rounded="true">
        <v-text-field
          hide-details
          single-line
          v-model="searchTerm"
          placeholder="Buscar por nombre o UPC del producto"
        ></v-text-field>
        <v-btn icon @click="getProduct()">
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
        <v-select
          style="padding-top: 20px;"
          v-model="selectedCategory"
          :items="categories"
          label="Categoria"
        ></v-select>
        <v-select
          style="padding-top: 20px;"
          v-model="productState"
          :items="['Todas', 'Activos', 'Inactivos']"
          label="Estado"
        ></v-select>
      </v-toolbar>
    </div>
    <div>
      <!-- Product List -->
      <v-container>
        <v-row>
          <v-col md="4" v-for="(data, index) of products" :key="index">
            <v-card class="mx-auto" max-width="344" outlined>
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="overline mb-4">
                    {{ data.get("Name") }}
                  </div>
                  <label>
                    {{ data.get("SubCategory") }}
                  </label>
                  <v-list-item-subtitle
                    >Precio: {{ data.get("Price") }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle
                    >IVU: {{ data.get("ivuPercent") * 100 }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-avatar tile size="80" color="grey">
                  <v-img
                    lazy-src="../assets/ImageNoAvailablejpg.jpg"
                    :src="getImg(data)"
                    :alt="data.get('Name')"
                  ></v-img>
                </v-list-item-avatar>
              </v-list-item>
              <v-card-actions>
                <v-btn outlined rounded text @click="openDialog(data)">
                  Edit
                </v-btn>
                <v-btn outlined rounded text @click="DeleteProduct(data)">
                  Delete
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- Pagination -->
    <div style="padding-bottom: 20px;">
      <v-pagination
        v-model="page"
        :length="length"
        :total-visible="10"
        @input="getProduct()"
      ></v-pagination>
    </div>
    <div style="text-align:center;">
      <v-form v-model="validSearch" class="form">
        <v-text-field
          type="number"
          @input="changeInput($event)"
          single-line
          style="width: 20%;"
          :rules="searchRules"
        >
        </v-text-field>
      </v-form>
    </div>
    <!-- Modals -->
    <v-dialog max-width="900px" v-model="dialog">
      <v-card>
        <v-card-title><span>Editar producto</span></v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-form>
                  <v-container>
                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="currentProduct.name"
                          label="Nombre del producto"
                          required
                        ></v-text-field>

                        <v-text-field
                          v-model="currentProduct.salePrice"
                          label="Precio de venta"
                          required
                          type="number"
                        ></v-text-field>

                        <v-text-field
                          v-model="currentProduct.barcode"
                          label="UPC"
                          required
                        ></v-text-field>

                        <v-select
                          label="Status"
                          v-model="currentProduct.outOfStock"
                          :items="[
                            { name: 'Activo', value: false },
                            { name: 'Inactivo', value: true }
                          ]"
                          item-text="name"
                          item-value="value"
                        ></v-select>
                      </v-col>
                      <v-col>
                        <v-text-field
                          v-model="currentProduct.buyPrice"
                          label="Precio de compra"
                          required
                          type="number"
                        ></v-text-field>
                        <v-text-field
                          v-model="currentProduct.offerPrice"
                          label="Precio de oferta"
                          required
                          type="number"
                        ></v-text-field>
                        <v-select
                          v-model="currentProduct.category"
                          :items="editProductCategories"
                          label="Categoria"
                        ></v-select>
                      </v-col>
                      <v-col>
                        <v-text-field
                          v-model="currentProduct.quantity"
                          label="Cantidad"
                          required
                          type="number"
                        ></v-text-field>
                        <v-text-field
                          v-model="currentProduct.exist"
                          label="Inventario Bajo"
                          required
                          type="number"
                        ></v-text-field>
                        <v-text-field
                          v-model="currentProduct.size"
                          label="Tamaño"
                          required
                          type="text"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="2">
                        <v-checkbox
                          v-model="currentProduct.applyIvu"
                          label="Aplica IVU"
                          color="red"
                          hide-details
                        ></v-checkbox>
                      </v-col>
                      <v-col>
                        <v-text-field
                          v-if="currentProduct.applyIvu == true"
                          label="IVU"
                          v-model="currentProduct.ivuPercent"
                          type="number"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col>
                        <v-checkbox
                          v-model="currentProduct.isOffer"
                          label="Offer"
                          color="red"
                          hide-details
                        ></v-checkbox>
                        <div class="star-rating" v-if="currentProduct.isOffer">
                          <a
                            class="space star"
                            @click="currentProduct.offerNumber = 1"
                            v-bind:class="{
                              selected: currentProduct.offerNumber >= 1
                            }"
                            >★</a
                          >
                          <a
                            class="space star"
                            @click="currentProduct.offerNumber = 2"
                            v-bind:class="{
                              selected: currentProduct.offerNumber >= 2
                            }"
                            >★</a
                          >
                          <a
                            class="space star"
                            @click="currentProduct.offerNumber = 3"
                            v-bind:class="{
                              selected: currentProduct.offerNumber >= 3
                            }"
                            >★</a
                          >
                          <a
                            class="space star"
                            @click="currentProduct.offerNumber = 4"
                            v-bind:class="{
                              selected: currentProduct.offerNumber >= 4
                            }"
                            >★</a
                          >
                          <a
                            class="space star"
                            @click="currentProduct.offerNumber = 5"
                            v-bind:class="{
                              selected: currentProduct.offerNumber >= 5
                            }"
                            >★</a
                          >
                        </div>
                        <!-- <v-text-field
                          v-if="currentProduct.isOffer"
                          label="Offer"
                          v-model="currentProduct.offerNumber"
                          type="number"
                        >
                        </v-text-field> -->
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-img
                          style="margin:auto; max-width: 700px;"
                          lazy-src="../assets/ImageNoAvailablejpg.jpg"
                          :src="currentProduct.img"
                          :alt="currentProduct.name"
                        ></v-img>
                      </v-col>
                      <v-col>
                        <label>Descripcion del producto</label>
                        <v-textarea
                          solo
                          name="input-7-4"
                          label="Descripcion del producto"
                          v-model="currentProduct.description"
                          no-resize
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-file-input
                  @change="onFileChange($event)"
                  counter
                  show-size
                  truncate-length="15"
                  accept="image/*"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Cerrar
          </v-btn>
          <v-btn color="blue darken-1" text @click="saveChanges()">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Parse from "parse";
export default {
  name: "FarmacyInventoryComponent",
  data() {
    return {
      validSearch: false,
      searchRules: [
        v =>
          (parseInt(v) > 0 && parseInt(v) <= this.getLength()) ||
          "La posicion elegida no es valida"
      ],
      farmacyId: JSON.parse(localStorage.getItem("store"))
        ? JSON.parse(localStorage.getItem("store")).idPharmacy
        : undefined,
      categories: [],
      editProductCategories: [],
      products: [],
      page: 1,
      length: 0,
      searchTerm: "",
      selectedCategory: "Todas",
      productState: "Todas",
      dialog: false,
      currentProduct: {
        size: "",
        name: "",
        productObjectParse: undefined,
        img: "",
        applyIvu: false,
        isShopper: false,
        isOffer: false,
        barcode: "",
        OriginalPrice: 0,
        shopperNumber: 0,
        offerNumber: 0,
        quantity: 0,
        category: "",
        description: "",
        ivuPercent: 0,
        exist: 500,
        buyPrice: "",
        salePrice: 0,
        offerPrice: 0,
        currentProductImage: undefined,
        outOfStock: false
      }
    };
  },
  mounted() {
    this.getCategories();
    this.getProduct();
  },
  methods: {
    getCategories() {
      Parse.Cloud.run("getSubCategory", {
        storeId: this.$route.params.id || this.farmacyId
      })
        .then(result => {
          this.categories = result.map(x => x.get("subCategory"));
          // this.categories.push();
          this.categories.unshift("Todas");
          this.editProductCategories = Array.from(this.categories);
          this.editProductCategories.shift();
          // this.categories
          // this.categories.reverse();
        })
        .catch(err => {
          this.$swal({
            title: "Ha ocurrido un error al obtener las categorias",
            titleText: err,
            icon: "error"
          });
        });
    },
    getImg(data) {
      let image;
      if (data.get("picture")) {
        image = data.get("picture").url();
        return image;
      } else if (data.get("image_url")) {
        return data.get("image_url");
      } else {
        return "../assets/ImageNoAvailablejpg.jpg";
      }
    },
    getProduct() {
      let params = {
        storeId: this.$route.params.id ? this.$route.params.id : this.farmacyId,
        searchTerm: this.searchTerm,
        skip: this.page - 1,
        category: this.selectedCategory,
        state: this.productState,
        isUpc: !isNaN(this.searchTerm) && this.searchTerm != ""
      };
      Parse.Cloud.run("getProductByStore", params)
        .then(result => {
          this.products = result.result;
          this.length = parseInt(result.count / 27);
        })
        .catch(err => {
          this.$swal({
            title: "Ha ocurrido un error al obtener las categorias",
            titleText: err,
            icon: "error"
          });
        });
    },
    getLength() {
      return this.length;
    },
    openDialog(product) {
      this.currentProduct.name = product.get("Name");
      this.currentProduct.img = this.getImg(product);
      this.currentProduct.productObjectParse = product;
      this.currentProduct.applyIvu = product.get("applyIvu");
      this.currentProduct.ivuPercent = product.get("ivuPercent") * 100;
      this.currentProduct.isOffer = product.get("IsOffer");
      this.currentProduct.offerNumber = product.get("offer");
      this.currentProduct.isShopper = product.get("esShopper");
      this.currentProduct.shopperNumber = product.get("shopper");
      this.currentProduct.quantity = product.get("quantity");
      this.currentProduct.category = product.get("SubCategory");
      this.currentProduct.description = product.get("Description");
      this.currentProduct.barcode = product.get("upc");
      this.currentProduct.salePrice = product.get("Price");
      this.currentProduct.OriginalPrice = product.get("OriginalPrice");
      this.currentProduct.offerPrice = product.get("offerPrice");
      this.currentProduct.buyPrice = product.get("buyPrice");
      this.currentProduct.size = product.get("packSize");
      this.currentProduct.outOfStock = product.get("outOfStock");
      this.dialog = true;
    },
    onFileChange(e) {
      this.currentProduct.img = URL.createObjectURL(e);
      this.currentProduct.currentProductImage = e;
    },
    QuitProductFromArray(item) {
      this.products = this.products.filter(x => x.id != item.id);
    },
    DeleteProduct(item) {
      this.$swal({
        title: this.languaje == "es" ? "Atencion!" : "Attention!",
        text:
          this.languaje == "es"
            ? "Estas seguro de eliminar este producto?"
            : "Are you sure to delete this product?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Delete"
      }).then(result => {
        if (result.value) {
          item
            .destroy()
            .then(() => {
              this.$swal({
                title: this.languaje == "es" ? "Eliminado!" : "Deleted!",
                text:
                  this.languaje == "es"
                    ? "Tu producto ha sido eliminado."
                    : "Your Product has been deleted.",
                type: "info"
              });
              this.QuitProductFromArray(item);
            })
            .catch(err => {
              this.$swal({
                title: "Error!",
                html: err,
                icon: "error"
              });
            });
        }
      });
    },
    saveChanges() {
      let parseObject = this.currentProduct.productObjectParse;
      parseObject.set("Name", this.currentProduct.name);
      parseObject.set("applyIvu", this.currentProduct.applyIvu);
      parseObject.set("ivuPercent", this.currentProduct.ivuPercent / 100);
      parseObject.set("IsOffer", this.currentProduct.isOffer);
      parseObject.set("offer", parseInt(this.currentProduct.offerNumber));
      parseObject.set("quantity", this.currentProduct.quantity);
      parseObject.set("SubCategory", this.currentProduct.category);
      parseObject.set("Description", this.currentProduct.description);
      parseObject.set("upc", this.currentProduct.barcode);
      parseObject.set("barcode", this.currentProduct.barcode);
      parseObject.set("Price", this.currentProduct.salePrice);
      parseObject.set("OriginalPrice", this.currentProduct.OriginalPrice);
      parseObject.set("outOfStock", !(this.currentProduct.quantity > 0));
      parseObject.set("buyPrice", this.currentProduct.buyPrice.toString());
      parseObject.set("offerPrice", this.currentProduct.offerPrice);
      parseObject.set("packSize", this.currentProduct.size);
      parseObject.set("outOfStock", this.currentProduct.outOfStock);
      if (this.currentProduct.currentProductImage != undefined) {
        const parseFile = new Parse.File(
          "img",
          this.currentProduct.currentProductImage
        );
        parseObject.set("picture", parseFile);
      }
      parseObject
        .save()
        .then(() => {
          this.$swal({
            title: "Producto Guardado con exito!",
            icon: "success"
          });
          this.dialog = false;
          this.getProduct();
        })
        .catch(err => {
          this.$swal({
            title: "Hubo un error al editar el producto",
            html: err,
            icon: "error"
          });
        });
    },
    changeInput(value) {
      if (this.validSearch) {
        this.page = parseInt(value);
      } else {
        this.page = 1;
      }
    }
  }
};
</script>
<style scoped>
.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.space {
  width: 20px;
  height: 20px;
  margin: 2px;
  border-radius: 5px;
}
.star {
  border: solid #4e4e4e 1px;
  color: #4e4e4e;
  font-size: 25px;
}
.selected {
  background-color: #f3c600;
}
</style>
