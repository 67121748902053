<template>
  <div>
    <div>
      <NavBar></NavBar>
    </div>
    <div class="generalContainer">
      <Header></Header>
      <br />
      <div>
        <div>
          <v-btn
            class="mx-2"
            fab
            color="#26A69A"
            @click="dialog = true"
            v-if="usuarioFarmacia.admin"
          >
            <v-icon dark>
              mdi-plus
            </v-icon>
          </v-btn>
          <v-btn
            depressed
            color="primary"
            data-toggle="modal"
            data-target="#barcodemodal"
            @click="clearProductModal()"
          >
            Buscar por codigo de barras
          </v-btn>
        </div>
        <div>
          <FarmacyInventory></FarmacyInventory>
        </div>
      </div>
      <!-- modal barcode -->
      <div
        class="modal fade"
        id="barcodemodal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="barcodemodalTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div
            style="height:90vh !important"
            class="modal-content add-product-form modalContainer"
          >
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">
                {{ $t("products.searchbyBarcode") }}
                <!-- {{
                  languaje == "es"
                    ? "Buscar Producto por Codigo de Barra"
                    : "Search Product by Barcode"
                }} -->
              </h5>
              <button
                type="button"
                class="btn btn-warning"
                v-if="usuarioFarmacia.admin"
                @click="enviarCorreo"
              >
                <i class="fas fa-barcode"></i>
                <i class="fas fa-paper-plane"></i>
              </button>
              <button
                type="button"
                class="btn btn-danger"
                data-dismiss="modal"
                aria-label="Close"
                @click="cerrar"
              >
                {{ $t("products.close") }}
                <!-- {{ languaje == "es" ? "Cerrar" : "Close" }} -->
              </button>
            </div>
            <div class="modal-body specialBody">
              <div class="container">
                <div class="mt-4 row">
                  <div class="col-2" v-if="!videoBarcode">
                    <button
                      class="btn btn-success"
                      @click="activateBarcodeVideo"
                    >
                      <i class="fas fa-barcode"></i>
                    </button>
                  </div>
                  <div class="col-7" v-if="!videoBarcode">
                    <input
                      type="text"
                      v-model="barcodeSearch"
                      v-if="languaje == 'es'"
                      class="form-control"
                      id="barcodesearch"
                      placeholder="Codigo de Barra"
                    />
                    <input
                      type="text"
                      v-model="barcodeSearch"
                      v-else
                      class="form-control"
                      id="barcodesearch"
                      placeholder="Barcode"
                    />
                  </div>
                  <div class="col-9" v-if="videoBarcode">
                    <button
                      class="btn btn-danger"
                      @click="desactivateBarcodeVideo"
                    >
                      <i class="fas fa-barcode"></i>
                      {{ $t("products.closeReader") }}
                      <!-- {{ languaje == "es" ? "Cerrar Lector" : "Close Reader" }} -->
                    </button>
                  </div>
                  <div class="col-3">
                    <button
                      @click="findByBarcode"
                      class="btn btn-primary btn-block"
                    >
                      {{ $t("products.search") }}
                      <!-- {{ languaje == "es" ? "Buscar" : "Search" }} -->
                    </button>
                  </div>
                </div>
                <hr />
                <v-quagga
                  v-if="videoBarcode"
                  :onDetected="logIt"
                  :readerSize="readerSize"
                  :readerTypes="['ean_reader']"
                ></v-quagga>
                <hr v-if="videoBarcode" />
                <div v-if="product">
                  <div class="mt-6">
                    <div class="form-group mt-4">
                      <img class="productImage" :src="image" />
                    </div>
                    <div class="form-group mt-4">
                      <label>
                        {{ $t("products.productName") }}
                        <!-- {{
                        languaje == "es"
                          ? "Nombre de Producto"
                          : "Product Name:"
                      }} -->
                      </label>
                      <input
                        type="text"
                        v-model="productName"
                        disabled
                        class="form-control"
                      />
                    </div>
                    <div class="row">
                      <div class="form-group col-6">
                        <label>
                          {{ $t("products.productCategory") }}
                          <!-- {{
                          languaje == "es"
                            ? "Categoria de Producto"
                            : "Product Category:" -->
                          <!-- }} -->
                        </label>
                        <input
                          type="text"
                          v-model="subCategory"
                          disabled
                          class="form-control"
                        />
                      </div>
                      <div class="form-group col-6">
                        <label>
                          {{ $t("products.productStatus") }}
                          <!-- {{
                          languaje == "es"
                            ? "Estado de Producto"
                            : "Product Status:"
                        }} -->
                        </label>
                        <input
                          type="text"
                          v-model="productStatus"
                          disabled
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="form-group">
                      <label>
                        {{ $t("products.productDescription") }}
                        <!-- {{
                        languaje == "es"
                          ? "Descripcion de Producto"
                          : "Product Description:"
                      }} -->
                      </label>
                      <textarea
                        type="text"
                        v-model="description"
                        disabled
                        class="form-control"
                      ></textarea>
                    </div>

                    <div class="row">
                      <div class="form-group col-6">
                        <label>
                          {{ $t("products.productQuantity") }}
                          <!-- {{
                          languaje == "es"
                            ? "Cantidad de Producto"
                            : "Product Quantity:"
                        }} -->
                        </label>
                        <input
                          type="text"
                          v-model="productQuantity"
                          disabled
                          class="form-control"
                        />
                      </div>
                      <div class="form-group col-6">
                        <label>IVU:</label>
                        <input
                          type="text"
                          v-model="totalIVU"
                          disabled
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="form-group col-4">
                        <label>
                          {{ $t("products.purchasePrice") }}
                          <!-- {{
                          languaje == "es"
                            ? "Precio de Compra"
                            : "Purchase Price:"
                        }} -->
                        </label>
                        <input
                          type="text"
                          v-model="originalPrice"
                          disabled
                          class="form-control"
                        />
                      </div>
                      <div class="form-group col-4">
                        <label>{{ $t("products.salePrice") }}</label>
                        <input
                          type="text"
                          v-model="productPrice"
                          disabled
                          class="form-control"
                        />
                      </div>
                      <div class="form-group col-4">
                        <label>{{ $t("products.offerPrice") }}</label>
                        <input
                          type="text"
                          v-model="specialProductPrice"
                          disabled
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="form-group col-6">
                        <label>Shopper:</label>
                        <div class="star-rating col col-6" v-if="esShopper">
                          <a
                            disabled
                            class="space star"
                            v-bind:class="{ selected: shopperA }"
                            >★</a
                          >
                          <a
                            disabled
                            class="space star"
                            v-bind:class="{ selected: shopperB }"
                            >★</a
                          >
                          <a
                            disabled
                            class="space star"
                            v-bind:class="{ selected: shopperC }"
                            >★</a
                          >
                          <a
                            disabled
                            class="space star"
                            v-bind:class="{ selected: shopperD }"
                            >★</a
                          >
                          <a
                            disabled
                            class="space star"
                            v-bind:class="{ selected: shopperE }"
                            >★</a
                          >
                        </div>
                        <div v-else>
                          <input
                            type="text"
                            v-if="languaje == 'es'"
                            value="No es Shopper"
                            disabled
                            class="form-control"
                          />
                          <input
                            type="text"
                            v-else
                            disabled
                            value="Not Shopper"
                            class="form-control"
                          />
                        </div>
                      </div>
                      <div class="form-group col-6">
                        <label>{{ $t("products.offer") }}</label>
                        <div class="star-rating col col-6" v-if="esOffer">
                          <a
                            disabled
                            class="space star"
                            v-bind:class="{ selected: offerA }"
                            >★</a
                          >
                          <a
                            disabled
                            class="space star"
                            v-bind:class="{ selected: offerB }"
                            >★</a
                          >
                          <a
                            disabled
                            class="space star"
                            v-bind:class="{ selected: offerC }"
                            >★</a
                          >
                          <a
                            disabled
                            class="space star"
                            v-bind:class="{ selected: offerD }"
                            >★</a
                          >
                          <a
                            disabled
                            class="space star"
                            v-bind:class="{ selected: offerE }"
                            >★</a
                          >
                        </div>
                        <div v-else>
                          <input
                            type="text"
                            v-if="languaje == 'es'"
                            value="No es Oferta"
                            disabled
                            class="form-control"
                          />
                          <input
                            type="text"
                            v-else
                            value="Not Offer"
                            disabled
                            class="form-control"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="container" v-else>
                  <div class="form-group mt-6">
                    <h3 class="text-center">{{ aviso }}</h3>
                    <button
                      class="btn btn-primary btn-block"
                      type="button"
                      data-dismiss="modal"
                      @click="openCreateProduct()"
                      v-if="usuarioFarmacia.admin && !videoBarcode"
                    >
                      {{ $t("products.addProduct") }}
                      <!-- {{
                        languaje == "es" ? "Agregar Producto" : "Add Product"
                      }} -->
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal -->
      <div
        class="modal fade"
        id="exampleModalCenter"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div
            style="height:90vh !important"
            class="modal-content add-product-form modalContainer"
          >
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">
                {{
                  modalStatus
                    ? $t("products.editProduct")
                    : $t("products.createProduct")
                }}
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                @click="cerrar"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body specialBody">
              <div
                class="col col-12 justify-content-center"
                v-if="!modalStatus"
              >
                <button
                  v-if="false && !modalStatus"
                  @click="changeMultiple()"
                  v-bind:class="{
                    'btn btn-primary': !multiple,
                    'btn btn-success': multiple
                  }"
                >
                  {{
                    multiple
                      ? $t("products.multipleProducts")
                      : $t("products.oneProduct")
                  }}
                </button>
              </div>
              <div class="mt-3" v-if="multiple">
                <form>
                  <div class="form-group">
                    <label>Insert Excell File:</label>
                    <input
                      type="file"
                      accept=".xls,.xlsx"
                      class="form-control"
                      @change="proccessFile"
                      id="fileRoute"
                    />
                  </div>
                  <div class="scroll">
                    <table class="table table-hover">
                      <thead class="thead-dark">
                        <tr>
                          <th scope="col">
                            {{ $t("products.numberFamily") }}
                            <!-- {{
                              languaje == "es"
                                ? "Numero de Familia"
                                : "Number Family"
                            }} -->
                          </th>
                          <th scope="col">
                            {{ $t("products.name") }}
                            <!-- {{ languaje == "es" ? "Nombre" : "Name" }} -->
                          </th>
                          <th scope="col">
                            {{ $t("products.suplier") }}
                            <!-- {{ languaje == "es" ? "Supplier" : "Suplidor" }} -->
                          </th>
                          <th scope="col">
                            {{ $t("products.packSize") }}
                            <!-- {{
                              languaje == "es"
                                ? "Pack Size"
                                : "Tamaño de Paquete"
                            }} -->
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(data, key) of dataInfo" :key="key">
                          <td>{{ data.ProductNumberFamily }}</td>
                          <td>{{ data.ProductName }}</td>
                          <td>{{ data.SupplierName }}</td>
                          <td>{{ data.PackSize }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </form>
                <div class="Container mt-3">
                  <input
                    v-if="languaje == 'es'"
                    type="button"
                    class="btn btn-primary btn-lg btn-block"
                    :disabled="!dataInfo"
                    @click="proccessMultipleProducts"
                    value="Procesar Multiples Farmacias"
                    id="btnGuardar"
                  />
                  <input
                    v-else
                    type="button"
                    class="btn btn-primary btn-lg btn-block"
                    :disabled="!dataInfo"
                    @click="proccessMultipleProducts"
                    value="Proccess Multiple Pharmacies"
                    id="btnGuardar"
                  />
                </div>
              </div>
              <div v-if="!multiple">
                <div class="row justify-cntent-center mt-3">
                  <div class="col-12">
                    <br />
                    <div class="row justify-content-center">
                      <div class="col-11 col-sm-11 col-md-6 col-lg-6 col-xl-6">
                        <div class="category">
                          <label class="form-label" for="exampleInputEmail1">
                            <i
                              class="fa fa-asterisk asteric"
                              v-if="!modalStatus"
                              aria-hidden="true"
                            ></i>
                            {{ $t("products.productName") }}
                          </label>
                          <input
                            v-model="productName"
                            type="text"
                            class="form-control modal-form productInput"
                            id="name"
                            :disabled="!usuarioFarmacia.admin"
                            aria-describedby="basic-addon1"
                          />
                        </div>
                      </div>
                      <div class="col-11 col-sm-11 col-md-6 col-lg-6 col-xl-6">
                        <div class="category">
                          <label class="form-label" for="exampleInputEmail1">
                            <i
                              class="fa fa-asterisk asteric"
                              v-if="!modalStatus"
                              aria-hidden="true"
                            ></i>
                            {{ $t("products.salePrice") }}
                            <!-- {{
                              languaje == "es"
                                ? "Precio de Venta:"
                                : "Sale Price:"
                            }} -->
                          </label>
                          <input
                            v-model="productPrice"
                            type="number"
                            id="price"
                            :disabled="!usuarioFarmacia.admin"
                            class="form-control modal-form productInput"
                            aria-describedby="basic-addon1"
                          />
                        </div>
                      </div>
                      <div class="col-11 col-sm-11 col-md-6 col-lg-6 col-xl-6">
                        <div class="category">
                          <label class="form-label" for="exampleInputEmail1">
                            <i
                              class="fa fa-asterisk asteric"
                              v-if="!modalStatus"
                              aria-hidden="true"
                            ></i>
                            {{ $t("products.purchasePrice") }}
                            <!-- {{
                              languaje == "es"
                                ? "Precio de Compra:"
                                : "Purchase Price:"
                            }} -->
                          </label>
                          <input
                            v-model="originalPrice"
                            :disabled="!usuarioFarmacia.admin"
                            type="number"
                            id="originalprice"
                            class="form-control modal-form productInput"
                            aria-describedby="basic-addon1"
                          />
                        </div>
                      </div>
                      <div class="col-11 col-sm-11 col-md-6 col-lg-6 col-xl-6">
                        <div class="category">
                          <label class="form-label" for="exampleInputEmail1">
                            {{ $t("products.offerPrice") }}:
                            <!-- {{
                              languaje == "es"
                                ? "Precio de Oferta:"
                                : "Offer Price:"
                            }} -->
                          </label>
                          <input
                            v-model="specialProductPrice"
                            :disabled="!usuarioFarmacia.admin"
                            type="number"
                            id="price"
                            class="form-control modal-form productInput"
                            aria-describedby="basic-addon1"
                          />
                        </div>
                      </div>
                      <div class="col-11 col-sm-11 col-md-6 col-lg-6 col-xl-6">
                        <div class="category">
                          <label class="form-label" for="exampleInputQuantity1">
                            <i
                              class="fa fa-asterisk asteric"
                              v-if="!modalStatus"
                              aria-hidden="true"
                            ></i>
                            {{ $t("products.quantity") }}:
                            <!-- {{
                              languaje == "es" ? " Cantidad:" : " Quantity:"
                            }} -->
                          </label>
                          <input
                            v-model="productQuantity"
                            :disabled="!usuarioFarmacia.admin"
                            type="number"
                            id="productQuantity"
                            class="form-control modal-form productInput"
                            aria-describedby="basic-addon1"
                            v-bind:class="{
                              aqui: this.quantity < this.adviceQuanty
                            }"
                          />
                        </div>
                      </div>
                      <div class="col-11 col-sm-11 col-md-6 col-lg-6 col-xl-6">
                        <div class="category">
                          <label class="form-label" for="exampleInputQuantity1">
                            <i
                              class="fa fa-asterisk asteric"
                              v-if="!modalStatus"
                              aria-hidden="true"
                            ></i>
                            {{ $t("products.lowInventory") }}:
                          </label>
                          <input
                            v-model="adviceQuanty"
                            :disabled="!usuarioFarmacia.admin"
                            type="number"
                            id="advicetQuantity"
                            class="form-control modal-form productInput"
                            aria-describedby="basic-addon1"
                          />
                        </div>
                      </div>
                      <div class="col-11 col-sm-11 col-md-6 col-lg-6 col-xl-6">
                        <div class="category">
                          <label class="form-label" for="exampleInputQuantity1">
                            <i
                              class="fa fa-asterisk asteric"
                              v-if="!modalStatus"
                              aria-hidden="true"
                            ></i>
                            {{ $t("products.barcode") }}
                            <!-- {{
                              languaje == "es"
                                ? " Codigo de Barras:"
                                : " Barcode:"
                            }} -->
                          </label>
                          <input
                            v-model="barcode"
                            :disabled="!usuarioFarmacia.admin"
                            type="text"
                            id="barcode"
                            class="form-control modal-form productInput"
                            aria-describedby="basic-addon1"
                          />
                        </div>
                      </div>
                      <div class="col-11 col-sm-11 col-md-6 col-lg-6 col-xl-6">
                        <div class="form-group">
                          <label
                            class="form-label"
                            for="exampleFormControlSelect1"
                            ><i
                              class="fa fa-asterisk asteric"
                              v-if="!modalStatus"
                              aria-hidden="true"
                            ></i>
                            {{ $t("products.category") }}
                            <!-- {{
                              languaje == "es" ? "Categoria:" : "Category:"
                            }} -->
                          </label>
                          <select
                            :disabled="!usuarioFarmacia.admin"
                            v-model="subCategory"
                            class="form-control category productInput"
                            style="font-family: 'Montserrat', sans-serif; font-size: 13px;"
                            id="exampleFormControlSelect1"
                          >
                            <option v-if="!subCat" class="title-category">
                              {{ $t("products.notCategories") }}
                              <!-- {{
                                languaje == "es"
                                  ? "No tiene Categorías Disponibles"
                                  : "There is not enable sub-category"
                              }} -->
                            </option>
                            <option
                              v-for="sub in subCat"
                              :value="sub.get('subCategory')"
                              :key="sub.id"
                              class="title-category"
                            >
                              {{ sub.get("subCategory") }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div
                      class="col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 mt-3 mb-3 text-center"
                      v-if="barcode || barcode != ''"
                    >
                      <barcode
                        :font-size="16"
                        v-bind:value="barcode"
                        :width="2"
                        :height="50"
                      ></barcode>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div class="category">
                        <label class="form-label" for="exampleInputEmail1">
                          {{ $t("products.productDetails") }}:
                          <!-- {{
                          languaje == "es"
                            ? "Detalles del Producto:"
                            : "Product's Details:"
                        }} -->
                        </label>
                        <textarea
                          v-model="description"
                          :disabled="!usuarioFarmacia.admin"
                          type="text"
                          id="description"
                          class="form-control modal-form productInput"
                          aria-describedby="basic-addon1"
                          cols="20"
                          rows="3"
                        ></textarea>
                      </div>
                    </div>
                    <br />
                    <div class="row justify-content-center">
                      <div class="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8">
                        <div class="category">
                          <label class="switch">
                            <input
                              :disabled="!usuarioFarmacia.admin"
                              type="checkbox"
                              v-model="isIvu"
                              @change="noAplicarIVU()"
                            />
                            <span class="slider round"></span>
                          </label>
                          <div style="margin-top: -40px; margin-left: 70px;">
                            <span>
                              {{ $t("products.notIvu") }}:
                              <!-- {{
                              languaje == "es"
                                ? "No Aplicar IVU:"
                                : "Do Not Apply IVU:"
                            }} -->
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                        <div class="category" v-if="!isIvu">
                          <label class="switch">
                            <input
                              :disabled="!usuarioFarmacia.admin"
                              type="checkbox"
                              @change="changeCustomIvu(esCustomIvu)"
                              v-model="esCustomIvu"
                            />
                            <span class="slider round"></span>
                          </label>
                          <div style="margin-top: -50px; margin-left: 70px;">
                            <input
                              v-model="customIVU"
                              type="text"
                              id="customIVU"
                              class="form-control modal-form productInput"
                              aria-describedby="basic-addon1"
                              :disabled="!esCustomIVU"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <div class="category mt-3" v-if="!isIvu">
                          <label class="switch">
                            <input
                              type="checkbox"
                              v-model="isOnePercent"
                              :disabled="!usuarioFarmacia.admin"
                              @change="soloOneIvu()"
                            />
                            <span class="slider round"></span>
                          </label>
                          <div style="margin-top: -40px; margin-left: 70px;">
                            <span>1 %</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <div class="category mt-3" v-if="!isIvu">
                          <label class="switch">
                            <input
                              type="checkbox"
                              v-model="isSevenPercent"
                              :disabled="!usuarioFarmacia.admin"
                              @change="soloSevenIVU()"
                            />
                            <span class="slider round"></span>
                          </label>
                          <div style="margin-top: -40px; margin-left: 70px;">
                            <span>7 %</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <div class="category mt-3" v-if="!isIvu">
                          <label class="switch">
                            <input
                              type="checkbox"
                              v-model="isElevenPercent"
                              :disabled="!usuarioFarmacia.admin"
                              @change="soloElevenIVU()"
                            />
                            <span class="slider round"></span>
                          </label>
                          <div style="margin-top: -40px; margin-left: 70px;">
                            <span>11.5 %</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div class="row justify-content-center">
                      <div class="col-12">
                        <div class="form-group">
                          <label
                            class="form-label"
                            for="exampleFormControlSelect1"
                            ><i
                              class="fa fa-asterisk asteric"
                              v-if="!modalStatus"
                              aria-hidden="true"
                            ></i>
                            {{ $t("products.picture") }}:
                            <!-- {{ languaje == "es" ? "Foto:" : "Picture:" }} -->
                          </label>
                          <br />
                          <div class="custom-file">
                            <input
                              @change="onFileSelected"
                              type="file"
                              class="custom-file-input"
                              id="inputGroupFile02"
                              accept=".png, .jpg, .jpeg"
                              :disabled="!usuarioFarmacia.admin"
                            />
                            <label
                              class="custom-file-label"
                              for="inputGroupFile02"
                              style="font-family: 'Montserrat', sans-serif; font-size: 13px;"
                              accept=".png, .jpg, .jpeg"
                              id="imgText"
                            >
                              {{ $t("products.choosePicture") }}:
                              <!-- {{
                                languaje == "es"
                                  ? "Selecciones una Foto:"
                                  : "Choose the picture:"
                              }} -->
                            </label>
                            <div
                              class="input-group-append"
                              v-if="picture != undefined"
                            >
                              <div class="text-center mt-3">
                                <img
                                  :src="picture.url()"
                                  v-bind:class="{
                                    productImageShow: rotationPicture == 0,
                                    productImageShow90: rotationPicture == 90,
                                    productImageShow180: rotationPicture == 180,
                                    productImageShow270: rotationPicture == 270
                                  }"
                                  alt=""
                                />
                                <div class="row">
                                  <div class="col-5 text-right">
                                    <button
                                      class="btn btn-info"
                                      @click="setrotation(false)"
                                    >
                                      <i class="fas fa-undo"></i>
                                    </button>
                                  </div>
                                  <div class="col-5">
                                    <button
                                      class="btn btn-info"
                                      @click="setrotation(true)"
                                    >
                                      <i class="fas fa-redo"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br v-for="data of 8" :key="data" />
                    <div class="mb-3 mt-3 row rankBox">
                      <div class="col col-6">
                        <label class="form-label m-4">
                          Shopper
                        </label>
                        <label class="switch">
                          <input
                            :disabled="!usuarioFarmacia.admin"
                            type="checkbox"
                            @change="changeShopper()"
                            v-model="esShopper"
                          />
                          <span class="slider round"></span>
                        </label>
                        <div
                          style="margin-top: -40px; margin-left: 70px;"
                        ></div>
                      </div>
                      <div class="star-rating mt-4 col col-6" v-if="esShopper">
                        <a
                          :disabled="!usuarioFarmacia.admin"
                          class="space star"
                          @click="onShopperA"
                          v-bind:class="{ selected: shopperA }"
                          >★</a
                        >
                        <a
                          :disabled="!usuarioFarmacia.admin"
                          class="space star"
                          @click="onShopperB"
                          v-bind:class="{ selected: shopperB }"
                          >★</a
                        >
                        <a
                          :disabled="!usuarioFarmacia.admin"
                          class="space star"
                          @click="onShopperC"
                          v-bind:class="{ selected: shopperC }"
                          >★</a
                        >
                        <a
                          :disabled="!usuarioFarmacia.admin"
                          class="space star"
                          @click="onShopperD"
                          v-bind:class="{ selected: shopperD }"
                          >★</a
                        >
                        <a
                          :disabled="!usuarioFarmacia.admin"
                          class="space star"
                          @click="onShopperE"
                          v-bind:class="{ selected: shopperE }"
                          >★</a
                        >
                      </div>
                    </div>
                    <div class="mb-3 mt-3 row rankBox">
                      <div class="col col-6">
                        <label class="form-label m-4">
                          {{ $t("products.offer") }}
                          <!-- {{ languaje == "es" ? "Oferta" : "Offer" }} -->
                        </label>
                        <label class="switch">
                          <input
                            :disabled="!usuarioFarmacia.admin"
                            type="checkbox"
                            @change="changeOffer()"
                            v-model="esOffer"
                          />
                          <span class="slider round"></span>
                        </label>
                        <div
                          style="margin-top: -40px; margin-left: 70px;"
                        ></div>
                      </div>
                      <div class="star-rating mt-4 col col-6" v-if="esOffer">
                        <a
                          :disabled="!usuarioFarmacia.admin"
                          class="space star"
                          @click="onOfferA"
                          v-bind:class="{ selected: offerA }"
                          >★</a
                        >
                        <a
                          :disabled="!usuarioFarmacia.admin"
                          class="space star"
                          @click="onOfferB"
                          v-bind:class="{ selected: offerB }"
                          >★</a
                        >
                        <a
                          :disabled="!usuarioFarmacia.admin"
                          class="space star"
                          @click="onOfferC"
                          v-bind:class="{ selected: offerC }"
                          >★</a
                        >
                        <a
                          :disabled="!usuarioFarmacia.admin"
                          class="space star"
                          @click="onOfferD"
                          v-bind:class="{ selected: offerD }"
                          >★</a
                        >
                        <a
                          :disabled="!usuarioFarmacia.admin"
                          class="space star"
                          @click="onOfferE"
                          v-bind:class="{ selected: offerE }"
                          >★</a
                        >
                      </div>
                    </div>
                    <div class="modal-footer mt-5">
                      <button
                        type="button"
                        class="btn btn-secondary"
                        data-dismiss="modal"
                        @click="cerrar"
                        style="font-family: 'Montserrat', sans-serif; font-size: 13px; height: 34px; background: #1e1e1e; border: 0;"
                      >
                        {{ $t("products.close") }}
                      </button>
                      <button
                        v-if="modalStatus != true"
                        @click="createProducts"
                        type="button"
                        class="btn btn-primary"
                        style="font-family: 'Montserrat', sans-serif; font-size: 13px; height: 34px; background: #1e1e1e; border: 0;"
                      >
                        {{ $t("products.saveChanges") }}
                        <!-- {{
                          languaje == "es" ? "Guardar Cambios" : "Save Changes"
                        }} -->
                      </button>
                      <button
                        v-if="modalStatus == true"
                        @click="saveChange"
                        type="button"
                        class="btn btn-primary"
                        :disabled="!usuarioFarmacia.admin"
                        style="font-family: 'Montserrat', sans-serif; font-size: 13px; height: 34px; background: #1e1e1e; border: 0;"
                      >
                        {{ $t("products.saveChanges") }}
                        <!-- {{
                          languaje == "es" ? "Guardar Cambios" : "Save Changes"
                        }} -->
                      </button>
                    </div>
                    <div class="category" v-if="uploading">
                      <div style="text-align: center;">
                        <span>
                          {{ $t("products.uploadingPicture") }}
                          <!-- {{
                           languaje == "es"
                            ? "Subiendo Foto"
                            : "Uploading Picture..."
                        }}  -->
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal -->
      <div v-if="backdrop" class="loader">
        <div :class="loader ? 'loader-show' : ''" class="loader-container ">
          <img src="../../assets/loader.svg" alt="" />
          <p class="loader-text">
            {{ $t("products.loading") }}
            <!-- {{ languaje == "es" ? "Cargando..." : "Loading..." }} -->
          </p>
        </div>
      </div>
    </div>
    <!-- Modals -->
    <v-dialog persistent max-width="900px" v-model="dialog">
      <v-card>
        <v-card-title><span>Crear producto</span></v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-form ref="form" lazy-validation v-model="valid">
                  <v-container>
                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="currentProduct.name"
                          label="Nombre del producto"
                          required
                          :rules="rules.productNameRules"
                        ></v-text-field>
                        <v-text-field
                          v-model="currentProduct.salePrice"
                          label="Precio de venta"
                          required
                          type="number"
                        ></v-text-field>
                        <v-text-field
                          v-model="currentProduct.barcode"
                          label="UPC"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field
                          v-model="currentProduct.buyPrice"
                          label="Precio de compra"
                          required
                          type="number"
                        ></v-text-field>
                        <v-text-field
                          v-model="currentProduct.offerPrice"
                          label="Precio de oferta"
                          required
                          type="number"
                        ></v-text-field>
                        <v-select
                          v-model="currentProduct.category"
                          :items="categories"
                          label="Categoria"
                          required
                        ></v-select>
                      </v-col>
                      <v-col>
                        <v-text-field
                          v-model="currentProduct.quantity"
                          label="Cantidad"
                          required
                          type="number"
                        ></v-text-field>
                        <v-text-field
                          v-model="currentProduct.exist"
                          label="Aviso poca Existencia"
                          required
                          type="number"
                        ></v-text-field>
                        <v-text-field
                          v-model="currentProduct.size"
                          label="Tamaño"
                          required
                          type="text"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="2">
                        <v-checkbox
                          v-model="currentProduct.applyIvu"
                          label="Aplica IVU"
                          color="red"
                          hide-details
                        ></v-checkbox>
                      </v-col>
                      <v-col>
                        <v-text-field
                          v-if="currentProduct.applyIvu == true"
                          label="IVU"
                          v-model="currentProduct.ivuPercent"
                          type="number"
                          required
                        >
                        </v-text-field>
                      </v-col>
                      <v-col>
                        <v-checkbox
                          v-model="currentProduct.isOffer"
                          label="Offer"
                          color="red"
                          hide-details
                        ></v-checkbox>
                        <div class="star-rating" v-if="currentProduct.isOffer">
                          <a
                            class="space star"
                            @click="currentProduct.offerNumber = 1"
                            v-bind:class="{
                              selected: currentProduct.offerNumber >= 1
                            }"
                            >★</a
                          >
                          <a
                            class="space star"
                            @click="currentProduct.offerNumber = 2"
                            v-bind:class="{
                              selected: currentProduct.offerNumber >= 2
                            }"
                            >★</a
                          >
                          <a
                            class="space star"
                            @click="currentProduct.offerNumber = 3"
                            v-bind:class="{
                              selected: currentProduct.offerNumber >= 3
                            }"
                            >★</a
                          >
                          <a
                            class="space star"
                            @click="currentProduct.offerNumber = 4"
                            v-bind:class="{
                              selected: currentProduct.offerNumber >= 4
                            }"
                            >★</a
                          >
                          <a
                            class="space star"
                            @click="currentProduct.offerNumber = 5"
                            v-bind:class="{
                              selected: currentProduct.offerNumber >= 5
                            }"
                            >★</a
                          >
                        </div>
                        <!-- <v-checkbox
                          v-model="currentProduct.isOffer"
                          label="Offer"
                          color="red"
                          hide-details
                        ></v-checkbox>
                        <v-text-field
                          v-if="currentProduct.isOffer"
                          label="Offer"
                          v-model="currentProduct.offerNumber"
                          type="number"
                        >
                        </v-text-field> -->
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-img
                          style="margin:auto;max-width: 700px;"
                          lazy-src="../../assets/ImageNoAvailablejpg.jpg"
                          :src="currentProduct.img"
                          :alt="currentProduct.name"
                          ref="img"
                        ></v-img>
                      </v-col>
                      <v-col>
                        <v-textarea
                          solo
                          name="input-7-4"
                          label="Descripcion del producto"
                          v-model="currentProduct.description"
                          no-resize
                          required
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-container>
                  <v-row>
                    <v-col>
                      <v-file-input
                        @change="onFileChange($event)"
                        counter
                        show-size
                        accept="image/*"
                        truncate-length="15"
                      ></v-file-input>
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeModal()">
            Cerrar
          </v-btn>
          <v-btn color="blue darken-1" text @click="saveChanges()">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import NavBar from "../../components/NavBar.vue";
import Vue from "vue";
import VueQuagga from "vue-quaggajs";
import VueBarcode from "vue-barcode";
import Parse from "parse";
import xlsx from "xlsx";
import VueSweetalert2 from "vue-sweetalert2";
import FarmacyInventory from "../../components/FarmacyInventory";
import Header from "../../components/Header";
Vue.use(VueQuagga);
Vue.use(VueSweetalert2, options);
const options = {
  confirmButtonColor: "#41b882",
  cancelButtonColor: "#ff7674"
};
export default {
  name: "Products",
  components: {
    NavBar,
    barcode: VueBarcode,
    FarmacyInventory,
    Header
  },
  data() {
    return {
      rules: {
        productNameRules: [
          v => !!v || "Name is required",
          v =>
            (v && v.length <= 50) ||
            "El nombre no debe contener mas de 50 letras"
        ]
      },
      dialog: false,
      currentProduct: {
        size: "",
        name: "",
        productObjectParse: undefined,
        img: "",
        applyIvu: true,
        isShopper: false,
        isOffer: false,
        barcode: "",
        OriginalPrice: 0,
        shopperNumber: 0,
        offerNumber: 0,
        quantity: 0,
        category: "",
        description: "",
        ivuPercent: 11.5,
        exist: 500,
        buyPrice: 0,
        salePrice: 0,
        offerPrice: 0,
        currentProductImage: undefined
      },
      email: "",
      userName: "",
      pharmacyId: "",
      rotationPicture: 0,
      aviso: "",
      barcodeSearch: "",
      barcode: "",
      adviceQuanty: 0,
      active: true,
      totalProducts: 0,
      offer: 0,
      shopper: 0,
      esCustomIVU: false,
      dataInfo: null,
      multiple: false,
      usuarioFarmacia: {},
      backdrop: false,
      loader: false,
      customIVU: 0,
      esCustomIvu: false,
      description: "",
      user: Parse.User.current(),
      product: [],
      categories: [],
      productName: "",
      productPrice: "",
      specialProductPrice: "",
      productQuantity: "",
      subCategory: "",
      picture: null,
      category: "",
      searchItem: "",
      subCat: null,
      modalStatus: null,
      objectData: null,
      originalPrice: null,
      isIvu: false,
      pictureUrl: null,
      isOnePercent: false,
      isSevenPercent: false,
      isElevenPercent: false,
      name: "",
      price: 0,
      quantity: 0,
      subCat2: null,
      image: null,
      oriPrice: null,
      pager: null,
      allData: null,
      esShopper: false,
      esOffer: false,
      shopperA: false,
      shopperB: false,
      shopperC: false,
      shopperD: false,
      shopperE: false,
      offerA: false,
      offerB: false,
      offerC: false,
      offerD: false,
      offerE: false,
      uploading: false,
      dataToSearch: "",
      dataToSearchByCategory: "",
      admin: null,
      superAdmin: null,
      products: [],
      languaje: "",
      totalActiveProducts: 0,
      productStatus: "",
      totalIVU: 0,
      valid: true,
      videoBarcode: false,
      readerSize: {
        width: 640,
        height: 480
      },
      detecteds: []
    };
  },
  mounted() {
    this.userName = this.user.get("fullName");
    this.email = this.user.get("email");
    this.seeUserStore();
    this.getStoreId();
    this.getCategories();
    this.pager = {
      totalItems: 0,
      currentPage: 0,
      pageSize: 15,
      totalPages: 0,
      startPage: 0,
      endPage: 0,
      startIndex: 0,
      endIndex: 0,
      pages: []
    };
    this.modalStatus = false;
    if (Parse.User.current() === null) {
      this.$router.push("/HelloWorld");
    }
    this.getSubCategory();
    this.dataToSearchByCategory = "OTC";
    this.getProducts();
    this.getProductsCategorias();
    this.getProductsActiveTotal();
  },
  watch: {
    dialog() {},
    barcodeSearch() {
      this.barcode = this.barcodeSearch;
      if (this.barcodeSearch !== "") {
        this.findByBarcode();
      } else {
        this.clearProductModal();
      }
    },
    productPrice() {
      if (this.productPrice === "") {
        this.productPrice = "1";
      } else if (this.productPrice === "0") {
        this.productPrice = "1";
      }
    },
    // specialProductPrice(){
    //   if(this.specialProductPrice === ""){
    //     this.specialProductPrice = "1";
    //   } else if(this.specialProductPrice === "0"){
    //     this.specialProductPrice = "1";
    //   }
    // },
    originalPrice() {
      if (this.originalPrice === "") {
        this.originalPrice = "1";
      } else if (this.originalPrice === "0") {
        this.originalPrice = "1";
      }
    },
    productQuantity() {
      if (this.productQuantity === "") {
        this.productQuantity = "1";
      } else if (this.productQuantity === "0") {
        this.productQuantity = "1";
      }
    },
    dataToSearch() {
      if (this.dataToSearch !== "") {
        this.getProductsFilteredBy();
      } else {
        this.getProducts();
      }
    },
    dataToSearchByCategory() {
      this.getProducts();
      this.getProductsCategorias();
      this.getProductsActiveTotal();
    }
  },
  methods: {
    closeModal() {
      this.dialog = false;
      this.$refs.form.reset();
      this.currentProduct.img = undefined;
    },
    getCategories() {
      Parse.Cloud.run("getSubCategory", {
        storeId: JSON.parse(localStorage.getItem("store")).idPharmacy
      })
        .then(result => {
          this.categories = result.map(x => x.get("subCategory"));
          // this.categories.push();
          // this.categories.unshift("ALL");
          // this.categories
          // this.categories.reverse();
        })
        .catch(err => {
          this.$swal({
            title: "Ha ocurrido un error al obtener las categorias",
            titleText: err,
            icon: "error"
          });
        });
    },
    onFileChange(e) {
      if (e) {
        this.currentProduct.img = URL.createObjectURL(e);
        this.currentProduct.currentProductImage = e;
      }
    },
    openCreateProduct() {
      this.dialog = true;
      this.cerrar();
    },
    saveChanges() {
      if (!this.$refs.form.validate()) {
        return;
      }
      const Product = Parse.Object.extend("Products");
      let product = new Product();
      product.set("Name", this.currentProduct.name);
      product.set("applyIvu", this.currentProduct.applyIvu);
      product.set("ivuPercent", this.currentProduct.ivuPercent / 100);
      product.set("IsOffer", this.currentProduct.isOffer);
      product.set("offer", parseInt(this.currentProduct.offerNumber));
      product.set("quantity", this.currentProduct.quantity.toString());
      product.set("SubCategory", this.currentProduct.category);
      product.set("Description", this.currentProduct.description);
      product.set("upc", this.currentProduct.barcode);
      product.set("barcode", this.currentProduct.barcode);
      product.set("Price", this.currentProduct.salePrice);
      product.set("buyPrice", this.currentProduct.buyPrice);
      product.set("offerPrice", this.currentProduct.offerPrice);
      product.set("packSize", this.currentProduct.size);
      product.set(
        "storeId",
        Parse.Object.extend("Store").createWithoutData(
          JSON.parse(localStorage.getItem("store")).idPharmacy
        )
      );
      product.set(
        "employee",
        Parse.User.createWithoutData(Parse.User.current().id)
      );
      product.set("outOfStock", !(this.currentProduct.quantity > 0));
      product.set(
        "OriginalPrice",
        this.currentProduct.OriginalPrice.toString()
      );
      if (this.currentProduct.currentProductImage != undefined) {
        const parseFile = new Parse.File(
          "img",
          this.currentProduct.currentProductImage
        );
        product.set("picture", parseFile);
      }
      product
        .save()
        .then(() => {
          this.$swal({
            title: "Producto Guardado con exito!",
            icon: "success"
          });
          this.dialog = false;
          this.$refs.form.reset();
          this.currentProduct.currentProductImage = undefined;
          this.currentProduct.img = "";
        })
        .catch(err => {
          this.$swal({
            title: "Hubo un error al editar el producto",
            html: err,
            icon: "error"
          });
        });
    },
    setrotation(direction) {
      if (direction === true) {
        this.rotationPicture += 90;
      } else {
        this.rotationPicture -= 90;
      }
      if (this.rotationPicture < 0) {
        if (this.rotationPicture === -90) {
          this.rotationPicture = 270;
        } else if (this.rotationPicture === -180) {
          this.rotationPicture = 180;
        } else if (this.rotationPicture === -270) {
          this.rotationPicture = 90;
        } else {
          this.rotationPicture = 0;
        }
      }
    },
    enviarCorreo() {
      this.barcodesendemail({
        Correo: this.usuarioFarmacia.email,
        Nombre: this.usuarioFarmacia.name
      });
    },
    barcodesendemail(cuerpoCorreo) {
      // eslint-disable-next-line
        Email.send({
        Host: "smtp.gmail.com",
        Username: "serviciotecnico@coopharma.coop",
        Password: "Mysh0p2020",
        To: cuerpoCorreo.Correo,
        From: "serviciotecnico@coopharma.coop",
        Subject: "Acceso a Mobile Barcode",
        Body:
          "<div style='background-color:#E0E0E0; padding: 20px; width: 80%;'>" +
          "<a href='www.coopharma.coop'><img src='https://coopharma.app/img/logo-coopharma.e5f23e05.png'></a>" +
          "</div>" +
          "<div style='margin:40px; width:70%; text-align: justify;'>" +
          "<p style = 'font-size: 20px;'>Hola " +
          cuerpoCorreo.Nombre +
          ",</p>" +
          "<p style = 'font-size: 20px;'>Este es un correo de acceso a Mobile Barcode de " +
          "<a href='www.coopharma.app' style='color:#0E9390; font-weight:bold;'>www.coopharma.app</a>, usa tu correo electronico y contrasena para validar" +
          ", Para poder proceder acceda via el boton a continuacion en este correo:</p>" +
          "<p><a href = 'www.coopharma.app/?#/barcode'>www.coopharma.app/?#/barcode</a></p>" +
          "<div><a href='www.coopharma.app/?#/barcode'><button style='width:100%;'><p>" +
          "<img src='https://coopharma.app/img/logo-coopharma.e5f23e05.png' style='width:150px;'>" +
          "<p style='font-size: 20px; font-weight:bold; color: #ED7422;'>Acceso a Mobile Barcode</p></button></a></div></div>" +
          "<br><br><p>Para cualquier duda o inconveniente puede llamar a (787) 520-6018</p>"
      }).then(() => {
        this.$swal({
          title: this.languaje == "es" ? "Atención" : "Attention",
          text:
            this.languaje == "es"
              ? "Correo Electronico de acceso de codigo de barra movil enviado a " +
                cuerpoCorreo.Correo +
                ", usa tu correo electronico y contrasena para validar"
              : "Email barcode mobile access has been sent to " +
                cuerpoCorreo.Correo +
                ", use your email and password to validate your user",
          icon: "info"
        });
      });
    },
    findByBarcode() {
      this.aviso = "";
      this.clearProductModal();
      const query = new Parse.Query("Products");
      query.include("barcode");
      query.include("employee");
      query.equalTo("employee", Parse.User.current());
      query.equalTo("barcode", this.barcodeSearch);

      query
        .first()
        .then(result => {
          this.product = result;
          this.objectData = this.product;
          this.esOffer = this.objectData.get("IsOffer");
          this.offer = this.objectData.get("offer");
          this.readOffer(this.offer);
          this.esShopper = this.objectData.get("esShopper");
          this.shopper = this.objectData.get("shopper");
          this.readShopper(this.shopper);
          this.name = this.objectData.get("Name");
          this.price = this.objectData.get("Price");
          this.subCat2 = this.objectData.get("SubCategory");
          this.image = this.objectData.get("picture").url();
          this.oriPrice = this.objectData.get("OriginalPrice");
          this.quantity = this.objectData.get("quantity");
          this.description = this.objectData.get("Description");
          this.adviceQuanty = this.objectData.get("productAdvice");
          this.barcode = this.objectData.get("barcode");
          this.productStatus =
            this.objectData.get("outOfStock") === false
              ? this.languaje === "es"
                ? "Disponible"
                : "Available"
              : this.languaje === "es"
              ? "Agotado"
              : "Out of Stock";

          if (this.objectData.attributes.ivuPercent === 0.07) {
            this.isSevenPercent = true;
          } else if (this.objectData.attributes.ivuPercent === 0.115) {
            this.isElevenPercent = true;
          } else if (this.objectData.attributes.ivuPercent === 0.01) {
            this.isOnePercent = true;
          } else {
            this.esCustomIvu = true;
            this.customIVU = (
              this.objectData.attributes.ivuPercent * 100
            ).toFixed(2);
          }
          this.specialProductPrice = this.objectData.attributes.preferenciaPrice;
          this.productPrice = this.price;
          this.originalPrice = this.oriPrice;
          this.isIvu = !this.objectData.attributes.applyIvu;
          this.productName = this.name;
          this.subCategory = this.subCat2;
          this.picture = this.objectData.attributes.picture;
          this.productQuantity = this.quantity;
          this.totalIVU = (this.objectData.attributes.ivuPercent * 100).toFixed(
            2
          );
        })
        .catch(() => {
          this.clearProductModal();
          this.aviso =
            this.languaje == "es"
              ? "Producto No Encontrado"
              : "Product Not Found";
        });
    },
    proccessMultipleProducts() {
      //   if (this.dataInfo) {
      //     for (let data of this.dataInfo) {
      //     }
      //   }
    },
    proccessFile(event) {
      let selectedFile;
      selectedFile = event.target.files[0];
      let data = [
        {
          name: "jayanth",
          data: "scd",
          abc: "sdef"
        }
      ];
      xlsx.utils.json_to_sheet(data, "out.xlsx");
      if (selectedFile) {
        let fileReader = new FileReader();
        fileReader.readAsBinaryString(selectedFile);
        fileReader.onload = event => {
          let data = event.target.result;
          let workbook = xlsx.read(data, { type: "binary" });
          workbook.SheetNames.forEach(sheet => {
            let rowObject = xlsx.utils.sheet_to_row_object_array(
              workbook.Sheets[sheet]
            );
            this.dataInfo = rowObject;
          });
        };
      }
    },
    changeMultiple() {
      if (this.multiple) {
        this.multiple = false;
      } else {
        this.multiple = true;
      }
    },
    changeCustomIvu(data) {
      this.soloCustomIVU();
      this.customIVU = 0;
      this.esCustomIVU = data;
    },
    seeUserStore() {
      if (JSON.parse(localStorage.getItem("usuarioFarmacia"))) {
        this.usuarioFarmacia = JSON.parse(
          localStorage.getItem("usuarioFarmacia")
        );
      }
    },
    async getStoreId() {
      await Parse.Cloud.run("getStore", {
        userId: Parse.User.current().id
      }).then(result => {
        this.pictureUrl = result.get("storePicture").url()
          ? result.get("storePicture").url()
          : "../assets/icons/coopharmaheart.png";
      });
    },
    cerrar() {
      this.shopperC = false;
      this.shopperB = false;
      this.shopperA = false;
      this.shopperE = false;
      this.shopperD = false;
      this.OfferC = false;
      this.OfferB = false;
      this.OfferA = false;
      this.OfferE = false;
      this.OfferD = false;
      this.desactivateBarcodeVideo();
    },
    onShopperA() {
      if (this.shopperA) {
        if (this.shopperC || this.shopperB || this.shopperE || this.shopperD) {
          this.shopperC = false;
          this.shopperB = false;
          this.shopperA = true;
          this.shopperE = false;
          this.shopperD = false;
        } else {
          this.shopperC = false;
          this.shopperB = false;
          this.shopperA = false;
          this.shopperE = false;
          this.shopperD = false;
        }
      } else {
        this.shopperA = true;
        this.shopperE = false;
        this.shopperD = false;
        this.shopperC = false;
        this.shopperB = false;
      }
      this.proccessShopper();
    },
    onShopperB() {
      if (this.shopperB) {
        if (this.shopperC || this.shopperE || this.shopperD) {
          this.shopperC = false;
          this.shopperB = true;
          this.shopperA = true;
          this.shopperE = false;
          this.shopperD = false;
        } else {
          this.shopperC = false;
          this.shopperB = false;
          this.shopperA = false;
          this.shopperE = false;
          this.shopperD = false;
        }
      } else {
        this.shopperB = true;
        this.shopperA = true;
        this.shopperE = false;
        this.shopperD = false;
        this.shopperC = false;
      }
      this.proccessShopper();
    },
    onShopperC() {
      if (this.shopperC) {
        if (this.shopperE || this.shopperD) {
          this.shopperC = true;
          this.shopperB = true;
          this.shopperA = true;
          this.shopperE = false;
          this.shopperD = false;
        } else {
          this.shopperC = false;
          this.shopperB = false;
          this.shopperA = false;
          this.shopperE = false;
          this.shopperD = false;
        }
      } else {
        this.shopperC = true;
        this.shopperB = true;
        this.shopperA = true;
        this.shopperE = false;
        this.shopperD = false;
      }
      this.proccessShopper();
    },
    onShopperD() {
      if (this.shopperD) {
        if (this.shopperE) {
          this.shopperC = true;
          this.shopperB = true;
          this.shopperA = true;
          this.shopperE = false;
          this.shopperD = true;
        } else {
          this.shopperC = false;
          this.shopperB = false;
          this.shopperA = false;
          this.shopperE = false;
          this.shopperD = false;
        }
      } else {
        this.shopperD = true;
        this.shopperC = true;
        this.shopperB = true;
        this.shopperA = true;
        this.shopperE = false;
      }
      this.proccessShopper();
    },
    onShopperE() {
      if (this.shopperE) {
        this.shopperE = false;
        this.shopperD = false;
        this.shopperC = false;
        this.shopperB = false;
        this.shopperA = false;
      } else {
        this.shopperE = true;
        this.shopperD = true;
        this.shopperC = true;
        this.shopperB = true;
        this.shopperA = true;
      }
      this.proccessShopper();
    },
    onOfferA() {
      if (this.offerA) {
        if (this.offerC || this.offerB || this.offerE || this.offerD) {
          this.offerC = false;
          this.offerB = false;
          this.offerA = true;
          this.offerE = false;
          this.offerD = false;
        } else {
          this.offerC = false;
          this.offerB = false;
          this.offerA = false;
          this.offerE = false;
          this.offerD = false;
        }
      } else {
        this.offerA = true;
        this.offerE = false;
        this.offerD = false;
        this.offerC = false;
        this.offerB = false;
      }
      this.proccessOffer();
    },
    onOfferB() {
      if (this.offerB) {
        if (this.offerC || this.offerE || this.offerD) {
          this.offerC = false;
          this.offerB = true;
          this.offerA = true;
          this.offerE = false;
          this.offerD = false;
        } else {
          this.offerC = false;
          this.offerB = false;
          this.offerA = false;
          this.offerE = false;
          this.offerD = false;
        }
      } else {
        this.offerB = true;
        this.offerA = true;
        this.offerE = false;
        this.offerD = false;
        this.offerC = false;
      }
      this.proccessOffer();
    },
    onOfferC() {
      if (this.offerC) {
        if (this.offerE || this.offerD) {
          this.offerC = true;
          this.offerB = true;
          this.offerA = true;
          this.offerE = false;
          this.offerD = false;
        } else {
          this.offerC = false;
          this.offerB = false;
          this.offerA = false;
          this.offerE = false;
          this.offerD = false;
        }
      } else {
        this.offerC = true;
        this.offerB = true;
        this.offerA = true;
        this.offerE = false;
        this.offerD = false;
      }
      this.proccessOffer();
    },
    onOfferD() {
      if (this.offerD) {
        if (this.offerE) {
          this.offerC = true;
          this.offerB = true;
          this.offerA = true;
          this.offerE = false;
          this.offerD = true;
        } else {
          this.offerC = false;
          this.offerB = false;
          this.offerA = false;
          this.offerE = false;
          this.offerD = false;
        }
      } else {
        this.offerD = true;
        this.offerC = true;
        this.offerB = true;
        this.offerA = true;
        this.offerE = false;
      }
      this.proccessOffer();
    },
    onOfferE() {
      if (this.offerE) {
        this.offerE = false;
        this.offerD = false;
        this.offerC = false;
        this.offerB = false;
        this.offerA = false;
      } else {
        this.offerE = true;
        this.offerD = true;
        this.offerC = true;
        this.offerB = true;
        this.offerA = true;
      }
      this.proccessOffer();
    },
    getStateProducts(state) {
      this.active = state;
      this.getProducts();
      this.getProductsActiveTotal();
    },
    getProductsFilteredBy() {
      let query = new Parse.Query("Products");
      query.include("storeId");
      query.include("categoryId");
      query.ascending("Name");
      query.equalTo("employee", Parse.User.current());
      query.equalTo("SubCategory", this.dataToSearchByCategory);
      query.fullText("Name", this.dataToSearch, { diacriticSensitive: false });
      query.find().then(products => {
        this.products = products;
      });
    },
    getProductsCategorias() {
      let query = new Parse.Query("Products");
      query.equalTo("employee", Parse.User.current());
      query.equalTo("SubCategory", this.dataToSearchByCategory);
      query.count().then(total => {
        this.totalProducts = total;
      });
    },
    getProductsActiveTotal() {
      let query = new Parse.Query("Products");
      query.equalTo("employee", Parse.User.current());
      // query.equalTo("SubCategory", this.dataToSearchByCategory);
      query.equalTo("outOfStock", !this.active);
      query.count().then(total => {
        this.totalActiveProducts = total;
      });
    },

    getProducts() {
      let query = new Parse.Query("Products");
      query.include("storeId");
      query.include("categoryId");
      query.include("outOfStock");
      query.ascending("Name");
      query.equalTo("outOfStock", !this.active);
      // query.equalTo("SubCategory", this.dataToSearchByCategory);
      query.equalTo("employee", Parse.User.current());
      query.find().then(products => {
        this.products = products;
      });
    },
    getSubCategory() {
      Parse.Cloud.run("getSubCategory", {
        userId: Parse.User.current().id
      }).then(result => {
        this.subCat = result;
      });
    },
    getImage(data) {
      let image;
      if (data.get("picture")) {
        image = data.get("picture").url();
        return image;
      } else if (data.get("image_url")) {
        return data.get("image_url");
      } else {
        return "../assets/ImageNoAvailablejpg.jpg";
      }
    },
    outOfStock(dat) {
      let state = true;
      dat.set("outOfStock", state);

      dat.save().then(() => {
        this.$swal({
          title: this.languaje == "es" ? "Atención" : "Attention",
          text:
            this.languaje == "es"
              ? "Producto Acabado, no disponible para ordenes"
              : "Out of Stock Product, it is not available for orders",
          icon: "info"
        });
        this.getStateProducts(state);
      });
    },
    activateOutOfStock(dat) {
      let state = false;
      dat.set("outOfStock", state);
      dat.save().then(() => {
        this.$swal({
          title: this.languaje == "es" ? "Atención" : "Attention",
          text:
            this.languaje == "es"
              ? "Producto Disponible, se encuentra disponible para ordenes"
              : "Available Product, it is available for orders",
          icon: "info"
        });
        this.getStateProducts(state);
      });
    },
    applyIvu(dat) {
      dat.set("applyIvu", true);
      dat.save().then(() => {
        this.gettingStores();
      });
    },
    notApplyIvu(dat) {
      dat.set("applyIvu", false);
      dat.save().then(() => {
        this.gettingStores();
      });
    },
    proccessShopper() {
      this.shopper = 0;
      if (this.esShopper) {
        if (
          this.shopperA &&
          !this.shopperB &&
          !this.shopperC &&
          !this.shopperD &&
          !this.shopperE
        ) {
          this.shopper = 1;
        } else if (
          this.shopperA &&
          this.shopperB &&
          !this.shopperC &&
          !this.shopperD &&
          !this.shopperE
        ) {
          this.shopper = 2;
        } else if (
          this.shopperA &&
          this.shopperB &&
          this.shopperC &&
          !this.shopperD &&
          !this.shopperE
        ) {
          this.shopper = 3;
        } else if (
          this.shopperA &&
          this.shopperB &&
          this.shopperC &&
          this.shopperD &&
          !this.shopperE
        ) {
          this.shopper = 4;
        } else if (
          this.shopperA &&
          this.shopperB &&
          this.shopperC &&
          this.shopperD &&
          this.shopperE
        ) {
          this.shopper = 5;
        } else {
          this.shopper = 0;
        }
      } else {
        this.shopper = 0;
      }
    },
    proccessOffer() {
      this.offer = 0;
      if (this.esOffer) {
        if (
          this.offerA &&
          !this.offerB &&
          !this.offerC &&
          !this.offerD &&
          !this.offerE
        ) {
          this.offer = 1;
        } else if (
          this.offerA &&
          this.offerB &&
          !this.offerC &&
          !this.offerD &&
          !this.offerE
        ) {
          this.offer = 2;
        } else if (
          this.offerA &&
          this.offerB &&
          this.offerC &&
          !this.offerD &&
          !this.offerE
        ) {
          this.offer = 3;
        } else if (
          this.offerA &&
          this.offerB &&
          this.offerC &&
          this.offerD &&
          !this.offerE
        ) {
          this.offer = 4;
        } else if (
          this.offerA &&
          this.offerB &&
          this.offerC &&
          this.offerD &&
          this.offerE
        ) {
          this.offer = 5;
        } else {
          this.offer = 0;
        }
      } else {
        this.offer = 0;
      }
    },
    readOffer(offer) {
      switch (offer) {
        case 0: {
          this.offerA = false;
          this.offerB = false;
          this.offerC = false;
          this.offerD = false;
          this.offerE = false;
          break;
        }
        case 1: {
          this.offerA = true;
          this.offerB = false;
          this.offerC = false;
          this.offerD = false;
          this.offerE = false;
          break;
        }
        case 2: {
          this.offerA = true;
          this.offerB = true;
          this.offerC = false;
          this.offerD = false;
          this.offerE = false;
          break;
        }
        case 3: {
          this.offerA = true;
          this.offerB = true;
          this.offerC = true;
          this.offerD = false;
          this.offerE = false;
          break;
        }
        case 4: {
          this.offerA = true;
          this.offerB = true;
          this.offerC = true;
          this.offerD = true;
          this.offerE = false;
          break;
        }
        case 5: {
          this.offerA = true;
          this.offerB = true;
          this.offerC = true;
          this.offerD = true;
          this.offerE = true;
          break;
        }
        default: {
          this.offerA = false;
          this.offerB = false;
          this.offerC = false;
          this.offerD = false;
          this.offerE = false;
          break;
        }
      }
    },
    readShopper(shopper) {
      switch (shopper) {
        case 0: {
          this.shopperA = false;
          this.shopperB = false;
          this.shopperC = false;
          this.shopperD = false;
          this.shopperE = false;
          break;
        }
        case 1: {
          this.shopperA = true;
          this.shopperB = false;
          this.shopperC = false;
          this.shopperD = false;
          this.shopperE = false;
          break;
        }
        case 2: {
          this.shopperA = true;
          this.shopperB = true;
          this.shopperC = false;
          this.shopperD = false;
          this.shopperE = false;
          break;
        }
        case 3: {
          this.shopperA = true;
          this.shopperB = true;
          this.shopperC = true;
          this.shopperD = false;
          this.shopperE = false;
          break;
        }
        case 4: {
          this.shopperA = true;
          this.shopperB = true;
          this.shopperC = true;
          this.shopperD = true;
          this.shopperE = false;
          break;
        }
        case 5: {
          this.shopperA = true;
          this.shopperB = true;
          this.shopperC = true;
          this.shopperD = true;
          this.shopperE = true;
          break;
        }
        default: {
          this.shopperA = false;
          this.shopperB = false;
          this.shopperC = false;
          this.shopperD = false;
          this.shopperE = false;
          break;
        }
      }
    },
    showEditModal(dat) {
      this.multiple = false;
      this.clearProductModal();
      this.modalStatus = true;
      this.objectData = dat;
      this.esCustomIvu = false;
      this.customIVU = 0;
      this.isSevenPercent = false;
      this.isElevenPercent = false;
      this.isOnePercent = false;
      this.rotationPicture =
        this.objectData.get("pictureRotate") === undefined
          ? 0
          : this.objectData.get("pictureRotate");
      this.esOffer = this.objectData.get("IsOffer");
      this.offer = this.objectData.get("offer");
      this.readOffer(this.offer);
      this.esShopper = this.objectData.get("esShopper");
      this.shopper = this.objectData.get("shopper");
      this.readShopper(this.shopper);
      this.name = this.objectData.get("Name");
      this.price = this.objectData.get("Price");
      this.subCat2 = this.objectData.get("SubCategory");
      this.image = this.objectData.get("picture").url();
      this.oriPrice = this.objectData.get("OriginalPrice");
      this.quantity = this.objectData.get("quantity");
      this.description = this.objectData.get("Description");
      this.adviceQuanty = this.objectData.get("productAdvice");
      this.barcode =
        this.objectData.get("barcode") !== undefined
          ? this.objectData.get("barcode")
          : "  ";

      if (this.objectData.attributes.ivuPercent === 0.07) {
        this.isSevenPercent = true;
      } else if (this.objectData.attributes.ivuPercent === 0.115) {
        this.isElevenPercent = true;
      } else if (this.objectData.attributes.ivuPercent === 0.01) {
        this.isOnePercent = true;
      } else {
        this.esCustomIvu = true;
        this.customIVU = (this.objectData.attributes.ivuPercent * 100).toFixed(
          2
        );
      }
      this.specialProductPrice = this.objectData.attributes.preferenciaPrice;
      this.productPrice = this.price;
      this.originalPrice = this.oriPrice;
      this.isIvu = !this.objectData.attributes.applyIvu;
      this.productName = this.name;
      this.subCategory = this.subCat2;
      this.picture = this.objectData.attributes.picture;
      this.productQuantity = this.quantity;

      let emailCorreo = Parse.User.current().attributes.email;
      let query = new Parse.Query("_User");
      query.startsWith("email", emailCorreo.slice(0, emailCorreo.indexOf("@")));
      query.find().then(users => {
        for (let user of users) {
          let id = user.id;
          let query2 = new Parse.Query("Products");
          query2.equalTo("Name", this.productName);
          query2.equalTo("employee", id);
          query2.find();
        }
      });
    },
    noAplicarIVU() {
      if (this.isIvu) {
        this.esCustomIvu = false;
        this.customIVU = 0;
        this.isOnePercent = false;
        this.isSevenPercent = false;
        this.isElevenPercent = false;
      }
    },
    soloCustomIVU() {
      this.customIVU = 0;
      this.isElevenPercent = false;
      this.isSevenPercent = false;
      this.isOnePercent = false;
    },
    soloOneIVU() {
      this.customIVU = 0;
      this.esCustomIvu = false;
      this.isElevenPercent = false;
      this.isSevenPercent = false;
    },
    soloSevenIVU() {
      this.customIVU = 0;
      this.esCustomIvu = false;
      this.isElevenPercent = false;
      this.isOnePercent = false;
    },
    soloElevenIVU() {
      this.customIVU = 0;
      this.esCustomIvu = false;
      this.isOnePercent = false;
      this.isSevenPercent = false;
    },
    create() {
      this.clearProductModal();
      this.modalStatus = false;
    },
    clearProductModal() {
      this.desactivateBarcodeVideo();
      this.adviceQuanty = 0;
      this.product = null;
      this.description = "";
      this.objectData = null;
      this.productName = "";
      this.subCategory = "OTC";
      this.productPrice = "1";
      this.originalPrice = "1";
      this.specialProductPrice = "1";
      this.picture = null;
      this.originalPicture = "";
      this.isIvu = true;
      this.productQuantity = "1";
      this.esCustomIvu = false;
      this.customIVU = 1;
      this.isOnePercent = false;
      this.isSevenPercent = false;
      this.isElevenPercent = false;
      this.esCustomIvu = false;
      this.esShopper = false;
      this.shopper = 0;
      this.esOffer = false;
      this.offer = 0;
      this.shopperA = false;
      this.shopperB = false;
      this.shopperC = false;
      this.shopperD = false;
      this.shopperE = false;
      this.offerA = false;
      this.offerB = false;
      this.offerC = false;
      this.offerD = false;
      this.offerE = false;
    },
    changeOffer() {
      this.offerA = false;
      this.offerB = false;
      this.offerC = false;
      this.offerD = false;
      this.offerE = false;
    },
    changeShopper() {
      this.shopperA = false;
      this.shopperB = false;
      this.shopperC = false;
      this.shopperD = false;
      this.shopperE = false;
    },
    agregarProductos() {
      this.aviso = "";
      this.modalStatus = false;
      this.clearProductModal();
    },
    saveChange() {
      this.backdrop = true;
      setTimeout(() => {
        this.loader = true;
      }, 200);
      this.objectData.set("Name", this.productName);
      this.objectData.set("Price", this.productPrice);
      this.objectData.set("OriginalPrice", this.originalPrice);
      this.objectData.set("SubCategory", this.subCategory);
      this.objectData.set("Description", this.description);
      this.objectData.set("picture", this.picture);
      this.objectData.set("applyIvu", !this.isIvu);
      this.objectData.set("quantity", this.productQuantity);
      this.objectData.set("shopper", this.shopper);
      this.objectData.set("esShopper", this.esShopper);
      this.objectData.set("offer", this.offer);
      this.objectData.set("IsOffer", this.esOffer);
      this.objectData.set("preferenciaPrice", this.specialProductPrice);
      this.objectData.set("productAdvice", parseInt(this.adviceQuanty));
      this.objectData.set("barcode", this.barcode);
      this.objectData.set("upc", this.barcode);
      this.objectData.set("upc11", parseInt(this.barcode));
      this.objectData.set("pictureRotate", this.rotationPicture);
      if (!this.isIvu) {
        if (
          !this.isOnePercent &&
          !this.isSevenPercent &&
          !this.isElevenPercent &&
          !this.esCustomIvu
        ) {
          this.$swal({
            title: this.languaje == "es" ? "Atencíon" : "Attention",
            text:
              this.languaje == "es"
                ? "Tiene que escoger un porcentaje"
                : "You have to select a porcentage",
            icon: "info"
          });
          setTimeout(() => {
            this.loader = false;
            this.backdrop = false;
          }, 200);
          return;
        }
      }
      if (
        (this.isOnePercent && this.isSevenPercent) ||
        (this.isOnePercent && this.isElevenPercent) ||
        (this.isElevenPercent && this.isSevenPercent) ||
        (this.isElevenPercent && this.esCustomIvu) ||
        (this.isOnePercent && this.esCustomIvu)
      ) {
        this.$swal({
          title: this.languaje == "es" ? "Atencíon" : "Attention",
          text:
            this.languaje == "es"
              ? "Tiene que escoger una sola opcion de IVU."
              : "You have to select only an IVU option",
          icon: "info"
        });
        setTimeout(() => {
          this.loader = false;
          this.backdrop = false;
        }, 200);
        return;
      } else {
        if (this.isOnePercent) {
          this.objectData.set("ivuPercent", 0.01);
        }
        if (this.isSevenPercent) {
          this.objectData.set("ivuPercent", 0.07);
        }
        if (this.isElevenPercent) {
          this.objectData.set("ivuPercent", 0.115);
        }
        if (this.esCustomIvu) {
          this.objectData.set("ivuPercent", this.customIVU / 100);
        }

        if (
          this.productPrice !== "0" &&
          this.originalPrice !== "0" &&
          this.productQuantity !== "0"
        ) {
          this.loader = false;
          this.backdrop = false;
          this.objectData.save().then(() => {
            this.loader = false;
            this.backdrop = false;
            document.getElementById("exampleModalCenter").click();
            this.clearProductModal();
            // this.gettingStores();
            this.$swal({
              title: this.languaje == "es" ? "Exito" : "Success",
              text:
                this.languaje == "es"
                  ? "Producto actualizado correctamente"
                  : "Product updated correctly",
              icon: "success"
            });
          });
        } else {
          this.loader = false;
          this.backdrop = false;
          this.$swal({
            title: this.languaje == "es" ? "Atención" : "Atention",
            text:
              this.languaje == "es"
                ? "No deben haber precios en cero"
                : "It must not have prices in cero",
            icon: "info"
          });
        }
      }
    },
    createProducts() {
      this.backdrop = true;
      setTimeout(() => {
        this.loader = true;
      }, 200);
      let percent = 0;
      if (
        this.productName === null ||
        this.productName === "" ||
        this.productPrice === null ||
        this.productPrice === "" ||
        this.subCategory === null ||
        this.subCategory === "" ||
        this.picture === null ||
        this.originalPrice === null ||
        this.productQuantity === null ||
        this.productQuantity === ""
      ) {
        this.$swal({
          title: this.languaje == "es" ? "Atencíon" : "Attention",
          text:
            this.languaje == "es"
              ? "Todos los campos son requeridos"
              : "All fields are required",
          icon: "info"
        });
        setTimeout(() => {
          this.loader = false;
          this.backdrop = false;
        }, 200);
        return;
      }
      if (!this.isIvu) {
        if (
          !this.isOnePercent &&
          !this.isSevenPercent &&
          !this.isElevenPercent &&
          !this.esCustomIvu
        ) {
          this.$swal({
            title: this.languaje == "es" ? "Atencíon" : "Attention",
            text:
              this.languaje == "es"
                ? "Tiene que escoger un porcentaje"
                : "You have to select a porcentage",
            icon: "info"
          });
          setTimeout(() => {
            this.loader = false;
            this.backdrop = false;
          }, 200);
          return;
        }
      }
      if (
        this.isIvu &&
        (this.isOnePercent ||
          this.isSevenPercent ||
          this.isElevenPercent ||
          this.esCustomIvu)
      ) {
        this.$swal({
          title: this.languaje == "es" ? "Atencíon" : "Attention",
          text:
            this.languaje == "es"
              ? "No puede tener un IVU con excento junto"
              : "It is not posible to have excent IVU together",
          icon: "info"
        });
        setTimeout(() => {
          this.loader = false;
          this.backdrop = false;
        }, 200);
        return;
      }
      Parse.Cloud.run("getStoreId", {
        userId: Parse.User.current().id
      }).then(result => {
        this.category = result.get("subCategories");
        if (
          (this.isOnePercent && this.isSevenPercent) ||
          (this.isOnePercent && this.isElevenPercent) ||
          (this.isOnePercent && this.esCustomIvu) ||
          (this.isElevenPercent && this.esCustomIvu) ||
          (this.isSevenPercent && this.esCustomIvu)
        ) {
          this.$swal({
            title: this.languaje == "es" ? "Atencíon" : "Attention",
            text:
              this.languaje == "es"
                ? "Tiene que escoger una sola opcion de ivu."
                : "You have to select only an IVU option",
            icon: "info"
          });
          setTimeout(() => {
            this.loader = false;
            this.backdrop = false;
          }, 200);
          return;
        }
        if (this.isOnePercent) {
          percent = 0.01;
        }
        if (this.isSevenPercent) {
          percent = 0.07;
        }
        if (this.isElevenPercent) {
          percent = 0.115;
        }
        if (this.esCustomIvu) {
          percent = this.customIVU / 100;
        }
        if (
          this.productPrice !== "0" &&
          this.originalPrice !== "0" &&
          this.productQuantity !== "0"
        ) {
          Parse.Cloud.run("createProduct", {
            prodPrice: this.productPrice,
            preferenciaPrice: this.specialProductPrice,
            originalPrice: this.originalPrice,
            prodCategory: this.category,
            prodDescription: this.description,
            prodName: this.productName,
            userId: Parse.User.current().id,
            storeId: result.id,
            subCategory: this.subCategory,
            picture: this.picture,
            ivu: !this.isIvu,
            percent: percent,
            quantity: this.productQuantity,
            shopper: this.shopper,
            esShopper: this.esShopper,
            offer: this.offer,
            esOffer: this.esOffer,
            barcode: this.barcode,
            productAdvice: parseInt(this.adviceQuanty)
          }).then(() => {
            document.getElementById("exampleModalCenter").click();
            this.clearProductModal();
            this.loader = false;
            this.backdrop = false;
            this.$swal({
              title: this.languaje == "es" ? "Exito" : "Success",
              text:
                this.languaje == "es"
                  ? "Producto creado correctamente"
                  : "Product created correctly",
              icon: "success"
            });
          });
        } else {
          this.loader = false;
          this.backdrop = false;
          this.$swal({
            title: this.languaje == "es" ? "Atención" : "Atention",
            text:
              this.languaje == "es"
                ? "No deben haber precios en cero"
                : "It must not have prices in cero",
            icon: "info"
          });
        }
      });
    },
    jsUcfirst(str) {
      str = str.split(" ");
      for (let i = 0, x = str.length; i < x; i++) {
        str[i] = str[i][0].toUpperCase() + str[i].substr(1);
      }
      return str.join(" ");
    },
    deleteItem(item) {
      this.$swal({
        title: this.languaje == "es" ? "Atencion!" : "Attention!",
        text:
          this.languaje == "es"
            ? "Estas seguro de eliminar este producto?"
            : "Are you sure to delete this product?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Delete"
      }).then(result => {
        if (result.value) {
          item.destroy().then(() => {
            // this.gettingStores();
          });
          this.$swal({
            title: this.languaje == "es" ? "Eliminado!" : "Deleted!",
            text:
              this.languaje == "es"
                ? "Tu producto ha sido eliminado."
                : "Your Product has been deleted.",
            icon: "info"
          });
        }
      });
    },
    logOut() {
      Parse.User.logOut().then(() => {
        localStorage.removeItem("usuarioFarmacia");
        localStorage.removeItem("stripeToken");
        localStorage.removeItem("archivoEmail");
        this.$router.push("/");
      });
    },
    onFileSelected(event) {
      if (event.path[0].files[0].size < 2097152) {
        // let self = this;
        const toBase64 = file =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
          });

        this.Main(toBase64);
      } else {
        this.$swal({
          title: this.languaje == "es" ? "Atencíon" : "Attention",
          text:
            this.languaje == "es"
              ? "Esta imagen no es posible procesarla, elija otra por favor"
              : "This picture is not possible to proccess it, please select another one",
          icon: "info"
        });
      }
    },
    Main: async function(toBase64) {
      const file = document.querySelector(".custom-file-input").files[0];
      this.uploading = true;
      this.picture = await toBase64(file);
      const base64Image = self.picture;
      const name = "photo.jpeg";
      const parseFile = new Parse.File(name, {
        base64: base64Image
      });
      parseFile.save().then(
        savedFile => {
          this.picture = savedFile;
          this.uploading = false;
        },
        err => {
          console.log(err);
          this.uploading = false;
        }
      );
    },
    logIt(data) {
      this.barcodeSearch = data.codeResult.code;
      this.desactivateBarcodeVideo();
    },
    activateBarcodeVideo() {
      this.barcodeSearch = "";
      this.videoBarcode = true;
    },
    desactivateBarcodeVideo() {
      this.videoBarcode = false;
    }
  }
};
</script>
<style scoped>
div.scroll {
  overflow-x: scroll;
  overflow-y: scroll;
  width: 100%;
  height: 500px;
}
.loader-container {
  background: #fff;
  width: 400px;
  height: 300px;
  transition: 0.3s all ease;
  transform: scale(0);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.loader {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 999999;
  overflow: hidden;
  display: flex;
  top: 0;
  background: rgba(0, 0, 0, 0.4);
  justify-content: center;
  align-items: center;
}

.loader-text {
  font-family: "segoe ui";
  font-style: italic;
  font-size: 20px;
}
.generalContainer {
  /* width: 100%; */
  height: 100vh;
  overflow: auto;
}
input::-webkit-input-placeholder {
  /* WebKit browsers */
  padding-left: 20px;
  letter-spacing: 0.1vw;
}
input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  padding-left: 20px;
  letter-spacing: 0.1vw;
}
input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  padding-left: 20px;
  letter-spacing: 0.1vw;
}
input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  padding-left: 20px;
  letter-spacing: 0.1vw;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #707070;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #707070;
}
.productImage {
  width: 100%;
  height: 300px;
  border-radius: 40px;
  object-fit: contain;
  object-position: center;
  margin: -10px;
}
.productImageShow {
  margin: 10px;
  width: 50%;
  height: 200px;
}
.productImageShow90 {
  margin: 10px;
  width: 50%;
  height: 200px;
  transform: rotate(90deg);
}
.productImageShow180 {
  margin: 10px;
  width: 50%;
  height: 200px;
  transform: rotate(180deg);
}
.productImageShow270 {
  margin: 10px;
  width: 50%;
  height: 200px;
  transform: rotate(270deg);
}
.asteric {
  size: 10px;
  color: #a50000ad;
}
.modalContainer {
  background-color: #e0e0e0;
  overflow-y: auto;
}
.specialBody {
  height: 450px !important;
}
/* switch classes */

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #1e1e1e;
}

input:focus + .slider {
  box-shadow: 0 0 1px #1e1e1e;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.productInput {
  width: 100%;
  margin: auto;
  display: block;
  height: 40px;
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.space {
  width: 20px;
  height: 20px;
  margin: 2px;
  border-radius: 5px;
}
.star {
  border: solid #4e4e4e 1px;
  color: #4e4e4e;
  font-size: 40px;
}

.star:hover {
  background-color: #d3ae09;
}

.selected {
  background-color: #f3c600;
}
/* switch classes */
.rankBox {
  height: 60px;
}
.finProduct {
  border-radius: 10px;
  background-color: #9c2b2b;
  font-weight: 700;
  color: #fff;
  font-size: 15px;
  padding: 10px;
  height: 40px;
  margin-top: 5px;
}
.aqui {
  border: 3px solid #af2b2b;
}

#contenedor video {
  max-width: 100%;
  width: 100%;
}
#contenedor {
  max-width: 100%;
  position: relative;
}
canvas {
  max-width: 100%;
}
canvas.drawingBuffer {
  position: absolute;
  top: 0;
  left: 0;
}
.barcodeout {
  width: 100px;
  height: 40px;
}

.label {
  font-size: 25px !important;
  /* color: #a19c9c !important; */
  font-weight: 300 !important;
}
.space {
  width: 20px;
  height: 20px;
  margin: 2px;
  border-radius: 5px;
}
.star {
  border: solid #4e4e4e 1px;
  color: #4e4e4e;
  font-size: 25px;
}
.selected {
  background-color: #f3c600;
}
</style>
